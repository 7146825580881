onload = (event) => {
  const button = document.querySelector('.frontpage-hero  button');

  if (button) {
    const container = document.querySelector('#content-section');
    const secondDiv = container.getElementsByTagName('section')[1];

    button.addEventListener('click', function () {
      secondDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    });
  }
};
