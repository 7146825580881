import { intersectionObserve } from './_intersection-observer';

const elements = document.querySelectorAll('.animate-up');
const mediaQuery = window.matchMedia('(min-width: 900px)');
intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
  (function (el) {
    if (intersectionRatio > 0 && !el.classList.contains('animate')) {
      const id = el.getAttribute('data-delay');
      if (mediaQuery.matches && id !== null) {
        setTimeout(() => {
          el.classList.add('animate');
        }, id);
      } else {
        el.classList.add('animate');
      }
    }
  })(element);
});
